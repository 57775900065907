"use client";
import React, { useState, useEffect } from 'react';
import { Quote, ChevronLeft, ChevronRight, Star } from 'lucide-react';

const TestimonialCard = ({ quote, author, position, company, rating }) => (
  <div className="bg-white rounded-2xl p-8 shadow-lg relative">
    {/* Quote Icon */}
    <div className="absolute -top-4 -left-4 bg-blue-600 rounded-full p-3">
      <Quote className="w-5 h-5 text-white" />
    </div>

    {/* Rating */}
    <div className="flex mb-4">
      {[...Array(rating)].map((_, i) => (
        <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
      ))}
    </div>

    {/* Quote */}
    <blockquote className="text-gray-600 mb-6">
      "{quote}"
    </blockquote>

    {/* Author */}
    <div className="flex items-center">
      <div className="flex-1">
        <div className="font-semibold text-gray-900">{author}</div>
        <div className="text-sm text-gray-500">{position}</div>
        <div className="text-sm text-blue-600">{company}</div>
      </div>
    </div>
  </div>
);

const LogoCarousel = ({ logos }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setScrollPosition((prev) => (prev + 1) % logos.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [logos.length]);

  return (
    <div className="relative overflow-hidden bg-gray-50 py-8 mt-12">
      <div 
        className="flex transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(-${scrollPosition * 16.666}%)` }}
      >
        {/* Duplicate logos for infinite scroll effect */}
        {[...logos, ...logos].map((logo, index) => (
          <div 
            key={index} 
            className="flex-none w-1/6 px-4"
          >
            <div className="bg-white p-6 rounded-lg shadow-sm h-24 flex items-center justify-center">
              {/* Using placeholder for logo */}
              <div className="text-gray-400 font-semibold text-center">
                {logo}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const TestimonialsSection = () => {
  const testimonials = [
    {
      quote: "iVLSI Technologies has been instrumental in helping us develop our next-generation semiconductor products. Their expertise and dedication to quality are unmatched.",
      author: "John Anderson",
      position: "Director of Engineering",
      company: "TechCorp Electronics",
      rating: 5
    },
    {
      quote: "The team's deep understanding of VLSI design and their innovative approach helped us reduce time-to-market significantly.",
      author: "Sarah Chen",
      position: "VP of Product Development",
      company: "InnovateSemi",
      rating: 5
    },
    {
      quote: "Outstanding technical expertise combined with excellent project management. They consistently deliver beyond expectations.",
      author: "Michael Roberts",
      position: "CTO",
      company: "Future Chips Inc",
      rating: 5
    }
  ];

  const clientLogos = [
    "TechCorp Electronics",
    "InnovateSemi",
    "Future Chips Inc",
    "Smart Systems Ltd",
    "Quantum Solutions",
    "NextGen Semiconductors"
  ];

  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        {/* Section Header */}
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            What Our 
            <span className="bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent"> Clients Say</span>
          </h2>
          <p className="text-gray-600 text-lg">
            Trusted by leading technology companies worldwide
          </p>
        </div>

        {/* Testimonials Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>

        {/* Client Logos */}
        <div className="mt-20">
          <h3 className="text-center text-xl font-semibold text-gray-900 mb-8">
            Trusted by Industry Leaders
          </h3>
          <LogoCarousel logos={clientLogos} />
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;